import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import { PlotRoutes } from 'Routes';
import { useFeatureFlagContext } from 'contexts/FeatureFlag.context';
import {
  useBrandDataForSocialListeningOnboarding,
  useTopicDataForSocialListeningOnboarding,
} from 'features/socialMediaListening/hooks/onboarding';
import {
  ListeningTopicType,
  Platform,
  PlotFeature,
  useSubmitInitialTopicParsingForSocialMediaListeningBrandOnboardingViewMutation,
} from 'graphql/generated';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { theme } from 'styles/theme';
import { SocialMediaListeningOnboardingAboutBrandFormView } from './sections/aboutBrandForm';
import { SocialMediaListeningOnboardingAddAnotherTopic } from './sections/addAnotherTopic';
import { SocialMediaListeningOnboardingBrandKnownNames } from './sections/brandKnownNames';
import { SocialMediaListeningOnboardingBrandName } from './sections/brandName';
import { SocialMediaListeningOnboardingBrandParsingSettings } from './sections/brandParsingSettings';
import { SocialMediaListeningOnboardingCommunicationStrategy } from './sections/communicationStrategy';
import { SocialMediaListeningOnboardingCountrySelection } from './sections/countrySelection';
import { SocialMediaListeningOnboardingDotIndicator } from './sections/dotIndicator';
import { SocialMediaListeningOnboardingBrandIntro } from './sections/intro';
import { SocialMediaListeningOnboardingKeywords } from './sections/keywords';
import { SocialMediaListeningOnboardingCompleted } from './sections/onboardingCompleted';
import { SocialMediaListeningOnboardingPostHistoryPreference } from './sections/postHistoryPreference';
import { SocialMediaListeningOnboardingPostPriorityInfo } from './sections/postPriorityInfo';
import { SocialMediaListeningOnboardingSentimentSubjects } from './sections/sentimentSubjects';
import { SocialMediaListeningOnboardingSignals } from './sections/signals';
import { SocialMediaListeningOnboardingSocialHandleForm } from './sections/socialHandleForm';
import { OnboardingStates } from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  mutation SubmitInitialTopicParsingForSocialMediaListeningBrandOnboardingView(
    $topicId: String!
    $daysCount: Int!
    $includeTiktok: Boolean
    $includeInstagram: Boolean
  ) {
    submitInitialTopicParsing(
      topicId: $topicId
      daysCount: $daysCount
      includeTiktok: $includeTiktok
      includeInstagram: $includeInstagram
    ) {
      message
      success
    }
  }
`;

export const SocialMediaListeningBrandOnboardingView = () => {
  const navigate = useNavigate();
  const { brandId: brandIdParam = '' } = useParams();

  const [submitInitialTopicParsing, { loading: submittingBrandParsing }] =
    useSubmitInitialTopicParsingForSocialMediaListeningBrandOnboardingViewMutation();

  const { isFeatureEnabled } = useFeatureFlagContext();
  const creatorTrackingEnabled = isFeatureEnabled(PlotFeature.CreatorTracking);

  const { brand } = useBrandDataForSocialListeningOnboarding({
    brandId: brandIdParam,
  });
  const brandMentionTopic = brand?.topics.find(
    (t) => t.type === ListeningTopicType.BrandMention,
  );

  const { topicActions, queries: topicQueries } =
    useTopicDataForSocialListeningOnboarding({
      topics: brand?.topics || [],
    });
  const { handleAddBrandMentionedTopic } = topicActions;
  const { getAIGeneratedTopicPrompt } = topicQueries;

  useEffect(() => {
    if (brand?.id && brandMentionTopic) {
      getAIGeneratedTopicPrompt(brandMentionTopic.id).then((d) => {
        if (!prioritizeInfo) {
          setPrioritizeInfo(
            d.data?.aiGeneratedTopicPrompt.priorityContent || '',
          );
        }

        if (!communicationStrategy) {
          setCommunicationStrategy(
            d.data?.aiGeneratedTopicPrompt.engagementPlan || '',
          );
        }
      });
    }
  }, [brand]); // eslint-disable-line react-hooks/exhaustive-deps

  const [prioritizeInfo, setPrioritizeInfo] = useState('');
  const [communicationStrategy, setCommunicationStrategy] = useState('');

  const [currentState, setCurrentState] = useState<OnboardingStates>(
    OnboardingStates.BrandIntro,
  );
  const [latestOnboardingState, setLatestOnboardingState] = useState(
    OnboardingStates.BrandIntro,
  );

  const Sequence = [
    OnboardingStates.BrandIntro,
    OnboardingStates.AskBrandName,
    OnboardingStates.AskBrandKnownNames,
    OnboardingStates.SocialHandleForm,
    OnboardingStates.CountrySelection,
    OnboardingStates.AboutBrandForm,
    OnboardingStates.PostPriorityInfo,
    OnboardingStates.CommunicationStrategy,
    OnboardingStates.Keywords,
    OnboardingStates.Signals,
    OnboardingStates.SentimentSubjects,
    OnboardingStates.ParsingSettings,
    OnboardingStates.PostHistoryPreference,
    ...(creatorTrackingEnabled ? [OnboardingStates.AddAnotherTopic] : []),
    OnboardingStates.OnboardingComplete,
  ];

  const onNextClick = () => {
    const currentIndex = Sequence.indexOf(currentState);
    if (currentIndex === Sequence.length - 1) {
      navigate(PlotRoutes.socialListening());
    } else {
      setCurrentState(Sequence[currentIndex + 1]);
      if (Sequence.indexOf(latestOnboardingState) < currentIndex + 1) {
        setLatestOnboardingState(Sequence[currentIndex + 1]);
      }
    }
  };

  const onSubmitTopicPrompt = async () => {
    if (!brand?.id) return;
    await handleAddBrandMentionedTopic(brand.id, brand.name, {
      communicationStrategy,
      prioritizationInfo: prioritizeInfo,
    });

    onNextClick();
  };

  const handleSubmitBrandParsing = async (daysCount: number) => {
    if (!brand?.id || !brandMentionTopic) {
      return;
    }

    await submitInitialTopicParsing({
      variables: {
        topicId: brandMentionTopic.id,
        daysCount,
        includeTiktok:
          brand?.creators?.some((c) => c.platform === Platform.Tiktok) || false,
        includeInstagram:
          brand?.creators?.some((c) => c.platform === Platform.Instagram) ||
          false,
      },
    });
    onNextClick();
  };

  return (
    <Box
      height="100vh"
      width="100vw"
      sx={{
        backgroundColor: theme.colors?.utility.blueberry,
      }}
    >
      <SocialMediaListeningOnboardingDotIndicator
        latestOnboardingState={latestOnboardingState}
        onboardingSequence={Sequence}
        currentState={currentState}
        onSelectState={setCurrentState}
      />
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        {currentState === OnboardingStates.BrandIntro && (
          <SocialMediaListeningOnboardingBrandIntro
            onContinue={() => setCurrentState(OnboardingStates.AskBrandName)}
          />
        )}
        {currentState === OnboardingStates.AskBrandName && (
          <SocialMediaListeningOnboardingBrandName
            brandId={brand?.id}
            onEnter={() => onNextClick()}
          />
        )}
        {currentState === OnboardingStates.AskBrandKnownNames && (
          <SocialMediaListeningOnboardingBrandKnownNames
            brandId={brand?.id}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.SocialHandleForm && (
          <SocialMediaListeningOnboardingSocialHandleForm
            creators={brand?.creators || []}
            brandId={brand?.id || ''}
            onNext={onNextClick}
          />
        )}
        {brand?.id && currentState === OnboardingStates.CountrySelection && (
          <SocialMediaListeningOnboardingCountrySelection
            selectedCountries={brand?.regions}
            brandId={brand?.id}
            onNext={onNextClick}
          />
        )}
        {brand?.id && currentState === OnboardingStates.AboutBrandForm && (
          <SocialMediaListeningOnboardingAboutBrandFormView
            onNext={() => onNextClick()}
            brandId={brand?.id}
          />
        )}
        {currentState === OnboardingStates.PostPriorityInfo && (
          <SocialMediaListeningOnboardingPostPriorityInfo
            value={prioritizeInfo}
            setValue={setPrioritizeInfo}
            onNext={() => {
              onNextClick();
            }}
          />
        )}
        {currentState === OnboardingStates.CommunicationStrategy && (
          <SocialMediaListeningOnboardingCommunicationStrategy
            value={communicationStrategy}
            setValue={setCommunicationStrategy}
            onNext={() => {
              onSubmitTopicPrompt();
            }}
          />
        )}
        {currentState === OnboardingStates.Keywords && (
          <SocialMediaListeningOnboardingKeywords
            topic={brandMentionTopic}
            onNext={onNextClick}
          />
        )}
        {brandMentionTopic && currentState === OnboardingStates.Signals && (
          <SocialMediaListeningOnboardingSignals
            topicId={brandMentionTopic.id}
            onNext={onNextClick}
          />
        )}
        {brand &&
          brandMentionTopic &&
          currentState === OnboardingStates.SentimentSubjects && (
            <SocialMediaListeningOnboardingSentimentSubjects
              currentTopicId={brandMentionTopic.id}
              brandId={brand.id}
              onNext={onNextClick}
            />
          )}
        {currentState === OnboardingStates.ParsingSettings && (
          <SocialMediaListeningOnboardingBrandParsingSettings
            brandId={brand?.id}
            onNext={onNextClick}
          />
        )}
        {currentState === OnboardingStates.PostHistoryPreference && (
          <SocialMediaListeningOnboardingPostHistoryPreference
            savingData={submittingBrandParsing}
            onNext={handleSubmitBrandParsing}
          />
        )}
        {currentState === OnboardingStates.AddAnotherTopic && (
          <SocialMediaListeningOnboardingAddAnotherTopic
            // savingData={submittingBrandParsing}
            brandId={brand?.id || ''}
            onNext={onNextClick}
            onPrev={() =>
              setCurrentState(OnboardingStates.PostHistoryPreference)
            }
          />
        )}
        {currentState === OnboardingStates.OnboardingComplete && (
          <SocialMediaListeningOnboardingCompleted />
        )}
      </Box>
    </Box>
  );
};
