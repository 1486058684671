import { Box } from '@mui/material';
import { FilterOption } from 'features/socialMediaListening/components/filterSelection/types';
import { SocialMediaListeningSelectedFilterItem } from 'features/socialMediaListening/components/selectedFilterItem';
import { ExtendedSocialPostTypeForFilter } from 'graphql/generated';
import { SocialListeningFiltersKey } from 'pages/socialMediaListening/constant';

interface SocialMediaListeningSelectedFiltersViewProps {
  selectedFilters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  };
  filterOptions: FilterOption<any>[];
  onFilterUpdated: (filters: {
    [key in SocialListeningFiltersKey]:
      | string
      | (string | Date | null)[]
      | boolean
      | undefined
      | number
      | ExtendedSocialPostTypeForFilter[];
  }) => void;
}

export const SocialMediaListeningSelectedFiltersView = ({
  selectedFilters,
  filterOptions,
  onFilterUpdated,
}: SocialMediaListeningSelectedFiltersViewProps) => {
  // return false if the filter is empty or the default value
  const shouldRenderFilter = (filterKey: SocialListeningFiltersKey) => {
    const filterValue = selectedFilters[filterKey];
    if (
      [
        SocialListeningFiltersKey.Platforms,
        SocialListeningFiltersKey.Statuses,
        SocialListeningFiltersKey.Followers,
        SocialListeningFiltersKey.Sentiments,
        SocialListeningFiltersKey.Period,
        SocialListeningFiltersKey.Languages,
      ].includes(filterKey) &&
      Array.isArray(filterValue) &&
      filterValue?.length === 0
    ) {
      return false;
    }
    if (
      filterKey === SocialListeningFiltersKey.MinimumPlayCount &&
      filterValue === 0
    ) {
      return false;
    }
    if (
      filterKey === SocialListeningFiltersKey.TopicRelevancy &&
      filterValue === 0.5
    ) {
      return false;
    }
    if (
      filterKey === SocialListeningFiltersKey.OrganicPaid &&
      filterValue === undefined
    ) {
      return false;
    }

    return true;
  };
  return (
    <Box
      sx={{
        display: 'flex',
        gap: 4,
        flexWrap: 'wrap',
        pb: 4,
      }}
    >
      {Object.keys(selectedFilters).map((filterKey) => {
        const filterOption = filterOptions.find(
          (option) => option.key === filterKey,
        );

        if (
          !filterOption ||
          !shouldRenderFilter(filterKey as SocialListeningFiltersKey)
        ) {
          return null;
        }

        return (
          <SocialMediaListeningSelectedFilterItem
            key={filterKey}
            filterOption={filterOption}
            selectedFilters={selectedFilters}
            onFilterUpdated={onFilterUpdated}
          />
        );
      })}
    </Box>
  );
};
