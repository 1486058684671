import { gql } from '@apollo/client';
import { Box, IconButton, Menu, Typography } from '@mui/material';
import { IconBoldSetting5 } from 'components/icons/components/bold/IconBoldSetting5';
import {
  BrandContentType,
  BrandInboundFiltersInput,
  SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment,
  SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragmentDoc,
  useGetOldestSocialPostForSlaAnalyticsFiltersQuery,
  useGetTopicForSlaAnalyticsFiltersQuery,
} from 'graphql/generated';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import { SLAAnalyticsFilterByAttributes } from './SLAAnalyticsFilterByAttributes';
import { SLAAnalyticsFilterByBrands } from './SLAAnalyticsFilterByBrands';
import { SLAAnalyticsFilterByCategory } from './SLAAnalyticsFilterByCategory';
import { SLAAnalyticsFilterByDemographics } from './SLAAnalyticsFilterByDemographics';
import { SLAAnalyticsFilterByPeriod } from './SLAAnalyticsFilterByPeriod';
import { SLAAnalyticsFilterByPlatform } from './SLAAnalyticsFilterByPlatform';
import { SLAAnalyticsFilterByProductLineModel } from './SLAAnalyticsFilterByProductLineModel';
import { SLAAnalyticsFilterBySignals } from './SLAAnalyticsFilterBySignals';
import { SLAAnalyticsFilterBySource } from './SLAAnalyticsFilterBySource';

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetTopicForSLAAnalyticsFilters($topicId: String!) {
    topic(id: $topicId) {
      id
      topicSignalDefinitions {
        id
        signalDefinition {
          ...SignalDefinitionFragmentSLAAnalyticsFilterBySignals
        }
      }
    }
  }
  ${SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragmentDoc}
`;

/**
 * SLAAnalyticsFilterValues extends from BrandInboundFiltersInput,
 * with extra values that are not needed for BE request, but needed for UI & FE functionality.
 */
export type SLAAnalyticsFilterValues = Omit<
  BrandInboundFiltersInput,
  'contentType'
> & {
  /**
   * For showing the correct selected date range label in the UI when loading saved filters from local storage.
   * On BE, we only care about startDate and endDate, but when mapping to UI, we need to know the label, too.
   */
  dateRangeLabel?: string;
};

/**
 * Method to clean up the extra values in SLAAnalyticsFilterValues
 * that are not needed for BE request. Otherwise, they will fail the request.
 */
export const cleanSLAAnalyticsFilterValues = (
  filters: SLAAnalyticsFilterValues,
): BrandInboundFiltersInput => {
  const { dateRangeLabel, ...cleanedFilters } = filters;

  return cleanedFilters as BrandInboundFiltersInput;
};

// eslint-disable-next-line @typescript-eslint/no-unused-expressions
gql`
  query GetOldestSocialPostForSLAAnalyticsFilters(
    $data: BrandInboundFiltersInput!
  ) {
    oldestSocialPostForBrandInboundFiltersInput(data: $data) {
      id
      platformCreateTime
    }
  }
`;

interface Props {
  brandId: string;
  topicId?: string;
  onChange: (filters: SLAAnalyticsFilterValues) => void;
  hideOptions?: {
    category?: boolean;
    creatorHandles?: boolean;
  };
  selectedFilters?: SLAAnalyticsFilterValues;
  contentType: BrandContentType;
  onSetCurrentTopicCategories?: (
    categories: SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment[],
  ) => void;
}

export const SLAAnalyticsFilters = ({
  brandId,
  topicId,
  contentType,
  onChange,
  hideOptions = {},
  selectedFilters,
  onSetCurrentTopicCategories,
}: Props) => {
  const [oldestPostDate, setOldestPostDate] = useState<Date>();

  const { data: oldestSocialPostData, refetch: refetchOldestSocialPostData } =
    useGetOldestSocialPostForSlaAnalyticsFiltersQuery({
      variables: {
        data: {
          ...cleanSLAAnalyticsFilterValues(selectedFilters!),
          contentType,
        },
      },
      skip: !selectedFilters || !!oldestPostDate,
      onCompleted: (data) => {
        setOldestPostDate(
          data.oldestSocialPostForBrandInboundFiltersInput?.platformCreateTime,
        );
      },
    });

  const [filterAnchorEl, setFilterAnchorEl] = useState<null | HTMLElement>(
    null,
  );
  const filterOpen = Boolean(filterAnchorEl);

  const [selectedDateRange, setSelectedDateRange] = useState<{
    label: string;
    range: Date[];
  }>(
    // If on mount, selectedFilters.dateRange is provided, set as custom range
    // TODO: This is a temporary solution, need to refactor this.
    // We might need to save the date range by label, instead of date objects because
    // date objects could change very easily as we don't remove the minute/second data.
    selectedFilters?.dateRange && selectedFilters?.dateRangeLabel
      ? {
          label: selectedFilters.dateRangeLabel,
          range: [
            selectedFilters.dateRange.startDate,
            selectedFilters.dateRange.endDate,
          ],
        }
      : {
          label: 'Last 7 days',
          range: [
            moment().subtract(7, 'day').startOf('day').toDate(),
            moment().toDate(),
          ],
        },
  );

  const { data: topicData } = useGetTopicForSlaAnalyticsFiltersQuery({
    variables: { topicId: topicId! },
    skip: !topicId,
  });

  const signalDefinitions = useMemo(
    () =>
      (topicData?.topic?.topicSignalDefinitions || []).map(
        (t) => t.signalDefinition,
      ),
    [topicData?.topic?.topicSignalDefinitions],
  );

  useEffect(() => {
    onSetCurrentTopicCategories?.(signalDefinitions);
  }, [signalDefinitions]); // eslint-disable-line

  const {
    selectedCreatorIds,
    selectedPlatforms,
    selectedPostTypes,
    selectedCategoryIds,
    selectedCategoryOptionIds,
    selectedGender,
    selectedGeneration,
    selectedSources,
    selectedCapturedProductBrandIds,
    selectedCapturedProductLineModels,
    selectedCapturedProductCategoryIds,
    selectedCapturedProductAttributeIds,
  } = useMemo(() => {
    const selectedSources = selectedFilters?.sources || [];
    const selectedCreatorIds = selectedFilters?.creatorIds || [];
    const selectedPlatforms = selectedFilters?.platforms || [];
    const selectedPostTypes = selectedFilters?.postTypes || [];
    const selectedCategoryIds = selectedFilters?.signalDefinitionIds || [];
    const selectedCategoryOptionIds =
      selectedFilters?.signalCategoryOptionIds || [];
    const selectedGender = selectedFilters?.gender || [];
    const selectedGeneration = selectedFilters?.generation || [];
    const selectedCapturedProductBrandIds =
      selectedFilters?.capturedProductBrandIds || [];
    const selectedCapturedProductLineModels =
      selectedFilters?.capturedProductLineModelInputs || [];
    const selectedCapturedProductCategoryIds =
      selectedFilters?.capturedProductCategoryIds || [];
    const selectedCapturedProductAttributeIds =
      selectedFilters?.capturedProductAttributeIds || [];
    return {
      selectedCreatorIds,
      selectedPlatforms,
      selectedPostTypes,
      selectedCategoryIds,
      selectedCategoryOptionIds,
      selectedGender,
      selectedGeneration,
      selectedSources,
      selectedCapturedProductBrandIds,
      selectedCapturedProductLineModels,
      selectedCapturedProductCategoryIds,
      selectedCapturedProductAttributeIds,
    };
  }, [JSON.stringify({ selectedFilters })]); // eslint-disable-line

  const onUpdateFilters = useCallback(
    (
      filters: Omit<
        BrandInboundFiltersInput,
        'contentType' | 'brandId' | 'dateRange'
      >,
    ) => {
      onChange({
        ...selectedFilters,
        ...filters,
        brandId,
        dateRangeLabel: selectedDateRange.label,
        dateRange: {
          startDate: selectedDateRange.range[0]!,
          endDate: selectedDateRange.range[1]!,
        },
      });
    },
    [selectedDateRange, selectedFilters, brandId], // eslint-disable-line
  );

  // Update filters when selected date range changes
  useEffect(() => {
    onUpdateFilters({});
  }, [selectedDateRange]); // eslint-disable-line

  useEffect(() => {
    onUpdateFilters({
      ...(selectedFilters?.topicIds?.[0] !== topicId && {
        signalCategoryOptionIds: [],
        signalDefinitionIds: [],
      }),
    });
  }, [topicId]); // eslint-disable-line

  return (
    <Box
      sx={{
        display: 'flex',
        gap: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
    >
      <SLAAnalyticsFilterByPlatform
        selectedPlatforms={selectedPlatforms}
        selectedTypes={selectedPostTypes}
        selectedCreatorIds={
          hideOptions?.creatorHandles ? [] : selectedCreatorIds
        }
        onChange={({
          selectedCreatorIds,
          selectedPlatforms,
          selectedTypes,
        }) => {
          onUpdateFilters({
            creatorIds: hideOptions?.creatorHandles ? [] : selectedCreatorIds,
            platforms: selectedPlatforms,
            postTypes: selectedTypes,
          });
        }}
        brandId={brandId}
        hideOptions={hideOptions}
      />
      <SLAAnalyticsFilterByPeriod
        oldestPostDate={oldestPostDate}
        selectedPeriod={{
          label: selectedDateRange.label,
          startDate: selectedDateRange.range[0]!,
          endDate: selectedDateRange.range[1]!,
        }}
        onToggle={setSelectedDateRange}
      />
      <IconButton
        sx={{
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(2),
          p: theme.spacing(2),
          color: theme.colors?.primary.black,
        }}
        disableRipple
        onClick={(e) => {
          setFilterAnchorEl(e.currentTarget);
        }}
      >
        <Box
          sx={{
            display: 'flex',
            gap: theme.spacing(2),
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'rgba(35, 6, 3, 0.05)',
              borderRadius: theme.spacing(1),
              padding: theme.spacing(1),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <IconBoldSetting5
              size={16}
              style={{
                color: theme.colors?.primary.black,
              }}
            />
          </Box>

          <Typography variant="subhead-xl">Add a filter</Typography>
        </Box>
      </IconButton>
      <Menu
        anchorEl={filterAnchorEl}
        open={filterOpen}
        onClose={() => setFilterAnchorEl(null)}
        PaperProps={{
          sx: {
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 8px 18px -6px rgba(24, 39, 75, 0.12), 0px 12px 42px -4px rgba(24, 39, 75, 0.12)',
            border: 'none',
            maxHeight: '70vh',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          },
        }}
      >
        {!hideOptions?.category && topicId && (
          <SLAAnalyticsFilterBySignals
            selectedSignalIds={selectedCategoryIds}
            selectedSignalOptionIds={selectedCategoryOptionIds}
            onChange={(categoryIds, categoryOptionIds) => {
              onUpdateFilters({
                signalCategoryOptionIds: categoryOptionIds,
                signalDefinitionIds: categoryIds,
              });
            }}
            signalDefinitions={signalDefinitions}
          />
        )}

        {contentType !== BrandContentType.Owned && (
          <SLAAnalyticsFilterByDemographics
            selectedGender={selectedGender}
            selectedGeneration={selectedGeneration}
            onChange={({ selectedGender, selectedGeneration }) => {
              onUpdateFilters({
                gender: selectedGender,
                generation: selectedGeneration,
              });
            }}
          />
        )}
        <SLAAnalyticsFilterBySource
          selectedSources={selectedSources}
          onChange={(selectedSources) => {
            onUpdateFilters({ sources: selectedSources });
          }}
        />

        <>
          <SLAAnalyticsFilterByBrands
            selectedBrandIds={selectedCapturedProductBrandIds}
            onChange={(selectedBrandIds) => {
              onUpdateFilters({
                capturedProductBrandIds: selectedBrandIds,
              });
            }}
            filters={{
              dateRange: {
                startDate: selectedDateRange.range[0]!,
                endDate: selectedDateRange.range[1]!,
              },
              topicIds: topicId ? [topicId] : [],
            }}
          />
          <SLAAnalyticsFilterByProductLineModel
            selectedProductLineModels={selectedCapturedProductLineModels}
            onChange={(models) => {
              onUpdateFilters({
                capturedProductLineModelInputs: models,
              });
            }}
            filters={{
              dateRange: {
                startDate: selectedDateRange.range[0]!,
                endDate: selectedDateRange.range[1]!,
              },
              topicIds: topicId ? [topicId] : [],
            }}
          />
          <SLAAnalyticsFilterByCategory
            selectedCategoryIds={selectedCapturedProductCategoryIds}
            onChange={(ids) => {
              onUpdateFilters({
                capturedProductCategoryIds: ids,
              });
            }}
            filters={{
              dateRange: {
                startDate: selectedDateRange.range[0]!,
                endDate: selectedDateRange.range[1]!,
              },
              topicIds: topicId ? [topicId] : [],
            }}
          />
          <SLAAnalyticsFilterByAttributes
            selectedAttributesIds={selectedCapturedProductAttributeIds}
            onChange={(selectedAttributesIds) => {
              onUpdateFilters({
                capturedProductAttributeIds: selectedAttributesIds,
              });
            }}
            filters={{
              dateRange: {
                startDate: selectedDateRange.range[0]!,
                endDate: selectedDateRange.range[1]!,
              },
              topicIds: topicId ? [topicId] : [],
            }}
          />
        </>
      </Menu>
    </Box>
  );
};
