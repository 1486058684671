import { gql } from '@apollo/client';
import { IconButton, Menu, Typography } from '@mui/material';
import { IconBoldCloseCircle } from 'components/icons/components/bold/IconBoldCloseCircle';
import { IconCustomSparkles } from 'components/icons/components/custom/IconCustomSparkles';
import { IconLinearBox } from 'components/icons/components/linear/IconLinearBox';
import { IconLinearBubbleSpark } from 'components/icons/components/linear/IconLinearBubbleSpark';
import { IconLinearDollarCircle } from 'components/icons/components/linear/IconLinearDollarCircle';
import { IconLinearGrid2 } from 'components/icons/components/linear/IconLinearGrid2';
import { IconLinearSecurityUser } from 'components/icons/components/linear/IconLinearSecurityUser';
import { IconLinearShop } from 'components/icons/components/linear/IconLinearShop';
import {
  GenderDemographicsMap,
  GenerationDemographicsMap,
} from 'features/socialListeningAnalytics/constants';
import {
  BrandInboundFiltersInput,
  SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment,
  useGetCapturedProductAttributeByIdsForSlaAnalyticsSelectedFilterItemQuery,
  useGetCapturedProductBrandByIdsForSlaAnalyticsSelectedFilterItemQuery,
  useGetCapturedProductCategoryByIdsForSlaAnalyticsSelectedFilterItemQuery,
} from 'graphql/generated';
import { useCallback, useMemo, useState } from 'react';
import { theme } from 'styles/theme';
import {
  SLAAnalyticsFilterByAttributes,
  SLAAnalyticsFilterByBrands,
  SLAAnalyticsFilterByCategory,
  SLAAnalyticsFilterByDemographics,
  SLAAnalyticsFilterByProductLineModel,
  SLAAnalyticsFilterBySignals,
  SLAAnalyticsFilterBySource,
} from '../slaAnalyticsFilters';

// eslint-disable-next-line
gql`
  query GetCapturedProductAttributeByIdsForSLAAnalyticsSelectedFilterItem(
    $input: GetCapturedProductAttributeByIdsInput!
  ) {
    attributeForCapturedProductAllLabelsByIds(input: $input) {
      id
      value
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetCapturedProductCategoryByIdsForSLAAnalyticsSelectedFilterItem(
    $input: GetCapturedProductCategoryByIdsInput!
  ) {
    categoryForCapturedProductAllLabelsByIds(input: $input) {
      id
      name
    }
  }
`;

// eslint-disable-next-line
gql`
  query GetCapturedProductBrandByIdsForSLAAnalyticsSelectedFilterItem(
    $input: GetBrandByIdsInput!
  ) {
    brandByIds(input: $input) {
      id
      name
    }
  }
`;

export type SLAAnalyticsSelectedFilterItemType =
  | 'category'
  | 'demographic'
  | 'source'
  | 'capturedProductBrand'
  | 'capturedProductProductLineModel'
  | 'capturedProductCategory'
  | 'capturedProductAttribute';

interface SLAAnalyticsSelectedFilterItemProps {
  selectedFilters: Omit<BrandInboundFiltersInput, 'contentType' | 'brandId'>;
  onChange: (
    filters: Omit<BrandInboundFiltersInput, 'contentType' | 'brandId'>,
  ) => void;
  type: SLAAnalyticsSelectedFilterItemType;
  signalDefinitions?: SignalDefinitionFragmentSlaAnalyticsFilterBySignalsFragment[];
}

export const SLAAnalyticsSelectedFilterItem = ({
  onChange,
  selectedFilters,
  type,
  signalDefinitions = [],
}: SLAAnalyticsSelectedFilterItemProps) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const { data: capturedProductAttributeData } =
    useGetCapturedProductAttributeByIdsForSlaAnalyticsSelectedFilterItemQuery({
      variables: {
        input: {
          attributeIds: selectedFilters.capturedProductAttributeIds || [],
        },
      },
      skip: type !== 'capturedProductAttribute',
    });

  const { data: capturedProductCategoryData } =
    useGetCapturedProductCategoryByIdsForSlaAnalyticsSelectedFilterItemQuery({
      variables: {
        input: {
          categoryIds: selectedFilters.capturedProductCategoryIds || [],
        },
      },
      skip: type !== 'capturedProductCategory',
    });

  const { data: capturedProductBrandData } =
    useGetCapturedProductBrandByIdsForSlaAnalyticsSelectedFilterItemQuery({
      variables: {
        input: {
          brandIds: selectedFilters.capturedProductBrandIds || [],
        },
      },
      skip: type !== 'capturedProductBrand',
    });

  const selectedFiltersLabel = useMemo(() => {
    if (type === 'demographic') {
      const genderLabels =
        selectedFilters.gender?.map((gender) => {
          return GenderDemographicsMap[gender];
        }) || [];
      const generationLabels =
        selectedFilters.generation?.map((generation) => {
          return GenerationDemographicsMap[generation];
        }) || [];
      return [...genderLabels, ...generationLabels].join(', ');
    }
    if (type === 'source') {
      return selectedFilters.sources?.join(', ');
    }
    if (type === 'category') {
      const categoryTitle =
        selectedFilters.signalDefinitionIds
          ?.map((id) => {
            const category = signalDefinitions.find((c) => c.id === id);
            return category ? category.title : null;
          })
          .filter(Boolean) ?? []; // Filter out any null values

      const categoryOptionTitle =
        selectedFilters.signalCategoryOptionIds
          ?.map((id) => {
            const option = signalDefinitions
              .flatMap((c) => c.options.find((option) => option.id === id))
              .find(Boolean);
            return option ? option.label : null;
          })
          .filter(Boolean) ?? [];

      return [...categoryTitle, ...categoryOptionTitle].join(', ');
    }

    if (
      type === 'capturedProductBrand' &&
      capturedProductBrandData?.brandByIds.length
    ) {
      return capturedProductBrandData.brandByIds
        .map((brand) => brand.name)
        .join(', ');
    }

    if (type === 'capturedProductProductLineModel') {
      return selectedFilters.capturedProductLineModelInputs
        ?.map((m) => `${m.line}${m.model && m.line && ' / '}${m.model}`)
        ?.join(', ');
    }

    if (
      type === 'capturedProductCategory' &&
      capturedProductCategoryData?.categoryForCapturedProductAllLabelsByIds
        .length
    ) {
      return capturedProductCategoryData.categoryForCapturedProductAllLabelsByIds
        .map((category) => category.name)
        .join(', ');
    }

    if (
      type === 'capturedProductAttribute' &&
      capturedProductAttributeData?.attributeForCapturedProductAllLabelsByIds
        .length
    ) {
      return capturedProductAttributeData?.attributeForCapturedProductAllLabelsByIds
        .map((attribute) => attribute.value)
        .join(', ');
    }

    return '';
  }, [
    selectedFilters.gender,
    selectedFilters.generation,
    selectedFilters.signalCategoryOptionIds,
    selectedFilters.signalDefinitionIds,
    selectedFilters.sources,
    signalDefinitions,
    selectedFilters.capturedProductLineModelInputs,
    type,
    capturedProductAttributeData?.attributeForCapturedProductAllLabelsByIds,
    capturedProductCategoryData?.categoryForCapturedProductAllLabelsByIds,
    capturedProductBrandData?.brandByIds,
  ]);

  const Icon = useMemo(() => {
    if (type === 'category') return IconCustomSparkles;
    if (type === 'demographic') return IconLinearSecurityUser;
    if (type === 'capturedProductBrand') return IconLinearShop;
    if (type === 'capturedProductProductLineModel') return IconLinearBox;
    if (type === 'capturedProductAttribute') return IconLinearBubbleSpark;
    if (type === 'capturedProductCategory') return IconLinearGrid2;
    return IconLinearDollarCircle;
  }, [type]);

  const getOptionComponent = useCallback(() => {
    const variant = 'normal';

    const capturedProductFilters = {
      topicIds: selectedFilters.topicIds,
      dateRange: selectedFilters.dateRange,
    };
    const renderTitle = (title: string) => {
      return (
        // eslint-disable-next-line
        <>
          <Typography variant="subhead-lg">{title}</Typography>
        </>
      );
    };
    if (type === 'demographic') {
      return (
        <SLAAnalyticsFilterByDemographics
          selectedGender={selectedFilters.gender || []}
          selectedGeneration={selectedFilters.generation || []}
          onChange={({ selectedGender, selectedGeneration }) => {
            onChange({
              ...selectedFilters,
              gender: selectedGender,
              generation: selectedGeneration,
            });
          }}
          variant={variant}
          renderTitle={() => renderTitle('Demographics')}
        />
      );
    }
    if (type === 'source') {
      return (
        <SLAAnalyticsFilterBySource
          onChange={(sources) => {
            onChange({ ...selectedFilters, sources });
          }}
          selectedSources={selectedFilters.sources || []}
          variant={variant}
          renderTitle={() => renderTitle('Sources')}
        />
      );
    }
    if (type === 'category') {
      return (
        <SLAAnalyticsFilterBySignals
          onChange={(selectedCategoryIds, selectedCategoryOptionIds) => {
            onChange({
              ...selectedFilters,
              signalDefinitionIds: selectedCategoryIds,
              signalCategoryOptionIds: selectedCategoryOptionIds,
            });
          }}
          signalDefinitions={signalDefinitions}
          selectedSignalIds={selectedFilters.signalDefinitionIds || []}
          selectedSignalOptionIds={
            selectedFilters.signalCategoryOptionIds || []
          }
          variant={variant}
          renderTitle={() => renderTitle('Signals')}
        />
      );
    }
    if (type === 'capturedProductBrand') {
      return (
        <SLAAnalyticsFilterByBrands
          onChange={(capturedProductBrandIds) => {
            onChange({ ...selectedFilters, capturedProductBrandIds });
          }}
          selectedBrandIds={selectedFilters.capturedProductBrandIds || []}
          variant={variant}
          renderTitle={() => renderTitle('Brands')}
          filters={capturedProductFilters}
        />
      );
    }

    if (type === 'capturedProductProductLineModel') {
      return (
        <SLAAnalyticsFilterByProductLineModel
          onChange={(capturedProductLineModelInputs) => {
            onChange({ ...selectedFilters, capturedProductLineModelInputs });
          }}
          selectedProductLineModels={
            selectedFilters.capturedProductLineModelInputs || []
          }
          variant={variant}
          renderTitle={() => renderTitle('Product Line & Model')}
          filters={capturedProductFilters}
        />
      );
    }

    if (type === 'capturedProductCategory') {
      return (
        <SLAAnalyticsFilterByCategory
          onChange={(capturedProductCategoryIds) => {
            onChange({ ...selectedFilters, capturedProductCategoryIds });
          }}
          selectedCategoryIds={selectedFilters.capturedProductCategoryIds || []}
          variant={variant}
          renderTitle={() => renderTitle('Categories')}
          filters={capturedProductFilters}
        />
      );
    }

    if (type === 'capturedProductAttribute') {
      return (
        <SLAAnalyticsFilterByAttributes
          onChange={(capturedProductAttributeIds) => {
            onChange({ ...selectedFilters, capturedProductAttributeIds });
          }}
          selectedAttributesIds={
            selectedFilters.capturedProductAttributeIds || []
          }
          variant={variant}
          renderTitle={() => renderTitle('Attributes')}
          filters={capturedProductFilters}
        />
      );
    }
    return null;
  }, [JSON.stringify({ onChange, selectedFilters, signalDefinitions, type })]); // eslint-disable-line

  const onRemoveFilter = () => {
    let updatedFilters = { ...selectedFilters };

    const filterResetMap = {
      category: { signalCategoryOptionIds: [], signalDefinitionIds: [] },
      source: { sources: [] },
      demographic: { gender: [], generation: [] },
      capturedProductBrand: { capturedProductBrandIds: [] },
      capturedProductProductLineModel: { capturedProductLineModelInputs: [] },
      capturedProductCategory: { capturedProductCategoryIds: [] },
      capturedProductAttribute: { capturedProductAttributeIds: [] },
    };

    // Reset the relevant filters based on the type
    if (filterResetMap[type]) {
      updatedFilters = { ...updatedFilters, ...filterResetMap[type] };
    }

    onChange(updatedFilters);
  };

  return (
    <>
      <IconButton
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 1,
          justifyContent: 'center',
          bgcolor: theme.colors?.utility[275],
          borderRadius: theme.spacing(8),
          p: theme.spacing(1, 2),
          maxWidth: '100%',
        }}
        disableRipple
        onClick={(e) => {
          e.stopPropagation();
          setAnchorEl(e.currentTarget);
        }}
      >
        <Icon
          size={16}
          color={theme.colors?.utility[900]}
          style={{
            flexShrink: 0,
          }}
        />
        <Typography variant="headline-xs" color={theme.colors?.utility[800]}>
          {selectedFiltersLabel}
        </Typography>
        <IconButton
          sx={{
            p: 0,
            color: theme.colors?.utility[600],
          }}
          disableRipple
          onClick={(e) => {
            e.stopPropagation();
            onRemoveFilter();
          }}
        >
          <IconBoldCloseCircle size={16} color={theme.colors?.utility[600]} />
        </IconButton>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: -8,
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: 244,
            padding: 4,
            background: 'rgba(255, 255, 255, 0.80)',
            backdropFilter: 'blur(20px)',
            boxShadow:
              '0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)',
            border: 'none',
            maxHeight: '60vh',
            overflowY: 'auto',
            '&::-webkit-scrollbar': {
              width: 0,
            },
          },
        }}
      >
        {getOptionComponent()}
      </Menu>
    </>
  );
};
